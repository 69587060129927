import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";

// Typen för din data
type DataPoint = {
  year: string;
  [key: string]: number | string;
};

// Typen för kontextens värden
interface DataContextValue {
  data: DataPoint[];
  loading: boolean;
  fetchSource: "local" | "api";
  setFetchSource: (source: "local" | "api") => void;
}

// Standardvärden för Context
const DataContext = createContext<DataContextValue>({
  data: [],
  loading: true,
  fetchSource: "local",
  setFetchSource: () => {},
});

const API_KEY = "AIzaSyCHqTJRB577ggVh43gUgWy_JKfMmpyGfyw";
const SHEET_ID = "1k-Jkv2Hb2Jsc4qR4vHxqagBIgO5GbEawzxOtd4Hkk1s";

// Provider för Context
export const DataProvider: React.FC<{
  localFile?: string;
  apiConfig: { range: string };
  times?: number;
  exclude?: string[];
  title?: string;
  children: ReactNode;
}> = ({ localFile, apiConfig, times = 1, exclude, title, children }) => {
  const [data, setData] = useState<DataPoint[]>([]);
  const [loading, setLoading] = useState(true);
  const [fetchSource, setFetchSource] = useState<"local" | "api">("local");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let result;
        if (fetchSource === "local") {
          const basePath = window.location.origin.includes("localhost")
            ? "/json-data" // Lokal utvecklingsmiljö
            : "/wp-content/themes/bjork/build/json-data"; // Produktionsmiljö i WordPress

          const localData = await fetch(`${basePath}/${apiConfig.range}.json`);
          result = await localData.json();
        } else if (fetchSource === "api") {
          const response = await fetch(
            `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/${apiConfig.range}?key=${API_KEY}`
          );
          result = await response.json();
        }
        // Bearbeta API-datan (som du gjorde tidigare)
        const rows = title ? result.values : result.values.slice(1);
        const years = rows[0].slice(1);
        const apiData = years.map((year: string) => ({ year }));
        const myDataRows = rows.slice(1);
        for (let i = 0; i < myDataRows.length; i++) {
          const row = myDataRows[i];
          const key = row[0];
          for (let c = 0; c < row.length; c++) {
            const cell = row[c];
            if (cell === "Förklaring") {
              apiData[-1] = {
                Förklaring: row[c + 1],
                title: title ?? result.values[0][0],
              };
            }
            if (c > 0 && !exclude?.includes(key)) {
              const cleanedValue = cell
                .replace(",", ".") // Ersätt "," med "."
                .replace("%", "") // Ta bort "%"
                .replace("−", "-") // Konvertera Unicode-minustecknet till ASCII
                .replace(/[^0-9.-]/g, "") // Tillåt endast siffror, punkt och bindestreck
                .trim(); // Ta bort tomma mellanslag runt om

              const parsedValue = parseFloat(cleanedValue); // Omvandla till flyttal
              apiData[c - 1] = {
                ...apiData[c - 1],
                [key]: isNaN(parsedValue) ? 0 : parsedValue * times, // Hantera ogiltiga värden
              };
            }
          }
          setData(apiData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [fetchSource, localFile, apiConfig, title, exclude, times]);

  return (
    <DataContext.Provider
      value={{ data, loading, fetchSource, setFetchSource }}
    >
      {children}
    </DataContext.Provider>
  );
};

// Hook för enkel åtkomst till Context
export const useDataContext = () => useContext(DataContext);
