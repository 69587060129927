import { DataProvider } from "./context/DataProvider";
import CustomBarChartResponsive from "./Graphs/BarChartResponsive";
import CustomLineChartResponsive from "./Graphs/LineChartResponsive";

const App = () => {
  return (
    <>
      <DataProvider apiConfig={{ range: "Översikt!A21:N32" }}>
        <CustomBarChartResponsive max={100} unit="%" />
      </DataProvider>
      <DataProvider
        exclude={["Totalt"]}
        times={1000}
        apiConfig={{ range: "Översikt!A62:N74" }}
      >
        <CustomBarChartResponsive unit="kr" />
      </DataProvider>
      <DataProvider
        exclude={["Totalt"]}
        times={1000}
        apiConfig={{ range: "Översikt!A101:N111" }}
      >
        <CustomBarChartResponsive unit="kr" />
      </DataProvider>
      <DataProvider times={1000} apiConfig={{ range: "Översikt!A137:N140" }}>
        <CustomLineChartResponsive hideLegend unit="kr" />
      </DataProvider>
      <DataProvider apiConfig={{ range: "Matchdag!A18:N21" }}>
        <CustomLineChartResponsive hideLegend unit="kr" />
      </DataProvider>
      <DataProvider apiConfig={{ range: "Matchdag!A47:N50" }}>
        <CustomLineChartResponsive hideLegend unit="%" />
      </DataProvider>
      <DataProvider apiConfig={{ range: "Marknad!A18:N21" }}>
        <CustomLineChartResponsive hideLegend unit="%" />
      </DataProvider>
      <DataProvider apiConfig={{ range: "Marknad!A47:N50" }}>
        <CustomLineChartResponsive hideLegend unit="%" />
      </DataProvider>
      <DataProvider
        times={1000}
        apiConfig={{ range: "Spelarförsäljning!A18:N21" }}
      >
        <CustomLineChartResponsive hideLegend unit="kr" />
      </DataProvider>
      <DataProvider apiConfig={{ range: "Spelarförsäljning!A47:N50" }}>
        <CustomLineChartResponsive hideLegend unit="%" />
      </DataProvider>
      <DataProvider apiConfig={{ range: "SEF och dylikt!A18:N21" }}>
        <CustomLineChartResponsive
          hideLegend
          unit="%"
          yAxis={[
            {
              id: "primary",
              position: "left",
              label: "Andel",
              min: 0,
              max: 10
            },
          ]}
        />
      </DataProvider>

      <DataProvider apiConfig={{ range: "SEF och dylikt!A44:N47" }}>
        <CustomLineChartResponsive
          hideLegend
          yAxis={[
            {
              id: "primary",
              position: "left",
              label: "Tabellplacering",
              min: 1,
              max: 16,
              reverse: true,
            },
          ]}
        />
      </DataProvider>

      <DataProvider apiConfig={{ range: "SEF och dylikt!A67:N70" }}>
        <CustomLineChartResponsive hideLegend unit="%" />
      </DataProvider>

      <DataProvider
        apiConfig={{ range: "Fotboll (inkl. internationella cuper)!A18:N21" }}
      >
        <CustomLineChartResponsive
          hideLegend
          yAxis={[
            {
              id: "primary",
              position: "left",
              label: "Tabellplacering",
              min: 1,
              max: 18,
              reverse: true,
            },
          ]}
        />
      </DataProvider>

      <DataProvider
        apiConfig={{ range: "Fotboll (inkl. internationella cuper)!A47:N50" }}
      >
        <CustomLineChartResponsive hideLegend />
      </DataProvider>

      <DataProvider
        apiConfig={{ range: "Fotboll (inkl. internationella cuper)!A75:N78" }}
      >
        <CustomLineChartResponsive
          hideLegend
          yAxis={[
            {
              id: "primary",
              position: "left",
              label: "Tabellplacering",
              min: 1,
              max: 20,
              reverse: true,
            },
          ]}
        />
      </DataProvider>

      <DataProvider
        apiConfig={{ range: "Fotboll (inkl. internationella cuper)!A107:N110" }}
      >
        <CustomLineChartResponsive hideLegend unit="%" />
      </DataProvider>

      <DataProvider apiConfig={{ range: "Medlemmar och supportrar!A18:K22" }}>
        <CustomLineChartResponsive />
      </DataProvider>

      <DataProvider apiConfig={{ range: "Medlemmar och supportrar!A47:K50" }}>
        <CustomLineChartResponsive hideLegend />
      </DataProvider>

      <DataProvider apiConfig={{ range: "Medlemmar och supportrar!A69:N72" }}>
        <CustomLineChartResponsive hideLegend />
      </DataProvider>

      <DataProvider apiConfig={{ range: "Medlemmar och supportrar!A99:N102" }}>
        <CustomLineChartResponsive hideLegend min={100} />
      </DataProvider>

      <DataProvider apiConfig={{ range: "Medlemmar och supportrar!A128:N131" }}>
        <CustomLineChartResponsive hideLegend />
      </DataProvider>

      <DataProvider
        apiConfig={{ range: "Styrelse, valberedning och organisation!A22:N25" }}
      >
        <CustomBarChartResponsive hideLegend />
      </DataProvider>
      <DataProvider
        apiConfig={{ range: "Styrelse, valberedning och organisation!A51:N54" }}
      >
        <CustomLineChartResponsive
          hideLegend
          yAxis={[
            {
              id: "primary",
              position: "left",
              label: "Antal",
              min: 1,
              max: 10,
            },
          ]}
        />
      </DataProvider>
      <DataProvider
        apiConfig={{ range: "Styrelse, valberedning och organisation!A86:N89" }}
      >
        <CustomLineChartResponsive
          hideLegend
          yAxis={[
            {
              id: "primary",
              position: "left",
              label: "Antal",
              min: 0,
              max: 80,
            },
          ]}
        />
      </DataProvider>
      {/* <DataProvider
        localFile="Ekonomi.A49:N58.json"
        apiConfig={{
          range: "Ekonomi - sammanställning!A49:M58",
        }}
      >
        <Incomes />
      </DataProvider>
      <Costs />
      <Members />
      <Result />
      <Audience />
      <Market />
      <Organization />
      <IncomeTrend />
      <MembersTrend />
      <PositionU19 />
      <Position />
      <Search />
      <Instagram /> */}
    </>
  );
};

export default App;
